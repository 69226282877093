import React from "react";
import {
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteMessage } from "../../slices/messages";
import { DeleteOperator, deleteDepartment } from "../../slices/operaters";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { deleteArticle } from "../../slices/helpdesk";
import { deleteConversation } from "../../slices/conversation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const DeleteMessageModal = ({ id, open, handleClose, data, ...rest }) => {
  const { statusDeleteMessage } = useSelector((state) => state.messages);
  const { statusDeleteArticle } = useSelector((state) => state.helpdesk);
  const { statusDeleteOperator, statusDeleteDepartment } = useSelector(
    (state) => state.operators
  );
  const { statusDeleteConversation } = useSelector(
    (state) => state.conversations
  );
  const { t } = useTranslation();
  const { clientConversationStored } = useSelector(
    (state) => state.conversations
  );
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const handleDeleteMessage = (id, messageId, messages, websiteId) => {
    dispatch(
      deleteMessage({
        messageId,
        messages,
        websiteId,
        conversationId: clientConversationStored._id,
      })
    ).then((res) => {
      !res?.error
        ? showSnackbar({
            message: t(`This message has been deleted successfully`),
            variant: "success",
          })
        : showSnackbar({
            message: "Error",
            variant: "error",
          });
      handleClose(id);
    });
  };

  const handleDeleteOperator = (operatorId, websiteId) => {
    dispatch(
      DeleteOperator({
        id: operatorId,
        websiteID: websiteId,
      })
    ).then((res) => {
      !res?.error
        ? showSnackbar({
            message: res.payload.isVerified
              ? `${t("The operator")} ${res.payload.firstName} ${
                  res.payload.lastName
                } ${t("has been deleted successfully")}`
              : `${t("The operator with email")} "${res.payload.email}"  ${t(
                  "has been deleted successfully"
                )}`,
            variant: "success",
          })
        : showSnackbar({
            message: "Error",
            variant: "error",
          });
      handleClose(id);
    });
  };

  const handleDeleteDepartment = (departmentId, websiteId) => {
    dispatch(
      deleteDepartment({
        id: departmentId,
        websiteID: websiteId,
      })
    ).then((res) => {
      !res?.error
        ? showSnackbar({
            message: `${t(res?.payload?.message)}`,
            variant: "success",
          })
        : showSnackbar({
            message: "Error",
            variant: "error",
          });
      handleClose(id);
    });
  };

  const handleDeleteArticle = (articleID, websiteID, articleTitle) => {
    dispatch(deleteArticle({ articleID, websiteID })).then((res) => {
      !res?.error
        ? showSnackbar({
            message: `${t("The article with title")} "${articleTitle}" ${t(
              "has been deleted successfully"
            )}`,
            variant: "success",
          })
        : showSnackbar({
            message: "Error",
            variant: "error",
          });
      handleClose(id);
    });
  };

  const handleDeleteConversation = (
    conversationId,
    websiteID,
    conversationStatus
  ) => {
    dispatch(
      deleteConversation({ conversationId, websiteID, conversationStatus })
    ).then((res) => {
      !res?.error
        ? showSnackbar({
            message: `"Conversation successfully deleted"`,
            variant: "success",
          })
        : showSnackbar({
            message: "Error",
            variant: "error",
          });
      handleClose(id);
    });
  };
  return (
    <Dialog open={open} onClose={() => handleClose(id)} className="info-modal">
      <LazyLoadImage
        src={data?.icon}
        className="assign-icon"
        alt="assign-icon"
      />
      <DialogTitle className="assign-operator-title">{data?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="assign-operator-text">
          {data?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="assign-operator-btns">
        <Button
          className="assign-operator-cancel-btn"
          onClick={() => handleClose(id)}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="delete-message-btn"
          disabled={
            statusDeleteMessage === "loading" ||
            statusDeleteArticle === "loading" ||
            statusDeleteOperator === "loading" ||
            statusDeleteConversation === "loading"
          }
          onClick={() => {
            data?.id === "delete-department" &&
              handleDeleteDepartment(data?.departmentId, data?.websiteId);

            data?.id === "delete-article" &&
              handleDeleteArticle(
                data?.articleID,
                data?.websiteID,
                data?.articleTitle
              );
            data?.id === "delete-message" &&
              handleDeleteMessage(
                id,
                data?.messageId,
                data?.messages,
                data?.websiteID
              );
            data?.id === "delete-operator" &&
              handleDeleteOperator(data?.operatorId, data?.websiteId);
            data?.id === "delete-conversation" &&
              handleDeleteConversation(
                data?.conversationId,
                data?.websiteID,
                data?.conversationStatus
              );
          }}
        >
          {statusDeleteMessage === "loading" ||
          statusDeleteArticle === "loading" ||
          statusDeleteOperator === "loading" ||
          statusDeleteDepartment === "loading" ||
          statusDeleteConversation === "loading" ? (
            <CircularProgress className="circular-progress-delete-modal" />
          ) : (
            t("Delete")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteMessageModal;
